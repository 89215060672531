<template>
  <nav>
    <ul class="flex flex-col items-center gap-2 pt-[26px] lg:gap-4 lg:p-8">
      <li
        v-for="page in pages"
        :key="page.id"
        class="w-full"
      >
        <NuxtLink
          :to="page.path"
          @click="emit('close')"
        >
          <UiButton
            class="justify-start px-5 py-4 text-xs lg:gap-3 lg:p-6 [&]:lg:text-base"
            :type="!mobile ? 'secondary' : 'tertiary'"
            :class="{
              '!bg-dnd_light_yellow': page.path === route.path && !mobile,
              'h-full': mobile,
            }"
          >
            <UiIcon
              v-if="page.iconName"
              :size="mobile ? 'sm' : ''"
              :name="page.iconName"
            />
            <span>{{ page.title }}</span>
          </UiButton>
        </NuxtLink>
      </li>
      <li
        :key="9"
        class="w-full"
      >
        <NuxtLink
          :to="siteInfo.TELEGRAM_BOT_LINK"
          target="_blank"
          external
          @click="emit('close')"
        >
          <UiButton
            class="justify-start px-5 py-4 text-xs lg:gap-3 lg:p-6 [&]:lg:text-base"
            :type="!mobile ? 'secondary' : 'tertiary'"
            :class="{ 'h-full': mobile }"
          >
            <UiIcon
              :size="mobile ? 'sm' : ''"
              name="telegram"
            />
            <span>Телеграм-бот</span>
          </UiButton>
        </NuxtLink>
      </li>
      <li class="pb-3 pt-5">
        <div
          class="text-dnd_red hover:text-dnd_red/70 flex cursor-pointer text-xs lg:text-base"
          @click="authStore.logout()"
        >
          <UiIcon
            name="quit"
            class="pr-2"
          />
          Выйти из профиля
        </div>
      </li>
    </ul>
  </nav>
</template>

<script setup>
import siteInfo from '~/helpers/siteInfo';

defineProps({
  mobile: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['close']);
const route = useRoute();
const pages = [
  // { id: 1, title: 'Главная', path: '/', iconName: 'house' },
  { id: 3, title: 'Мой займ', path: '/loan', iconName: 'stickers' },
  { id: 4, title: 'Моя заявка', path: '/long', iconName: 'plusSquare' },
  { id: 2, title: 'Мой профиль', path: '/profile', iconName: 'user' },
  { id: 5, title: 'Мои карты', path: '/cards', iconName: 'creditCard' },
  // { id: 6, title: 'Приведи друга', path: '/invite', iconName: 'people' },
  // { id: 7, title: 'Обратная связь', path: '/feedback', iconName: 'chatDots' },
  { id: 8, title: 'Вопрос-ответ', path: '/faq', iconName: 'patchQuestion' },
  { id: 10, title: 'Чат с оператором', path: '/chat', iconName: 'chatDots' },
  { id: 11, title: 'Подать обращение', path: '/appeal', iconName: 'envelope' },
];

const authStore = useAuthStore();
</script>

<style scoped></style>
