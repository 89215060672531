<template>
  <SwipeReload>
    <div class="flex flex-col min-h-[100vh]">
      <Header />
      <div class="customContainer flex gap-x-5 grow py-4 mx-auto lg:p-10">
        <aside
          class="hidden self-start rounded-[40px] bg-white shadow-md lg:block lg:min-w-[300px]"
        >
          <Navigation />
        </aside>
        <main class="mx-auto w-full">
          <div class="mx-4 rounded-3xl bg-white shadow-md lg:mx-0 lg:rounded-[40px]">
            <MainOverdueNotification v-if="userStore.user.status_code === 10" />
            <article
              class="flex flex-col items-center justify-center gap-y-8 px-4 py-6 lg:py-8 lg:gap-y-10 lg:px-8 h-full"
            >
              <slot />
            </article>
          </div>
          <Notifications :mapped-store="notieStore" />
        </main>
      </div>
      <Footer class="max-lg:hidden" />
    </div>
    <template v-if="route.path != '/chat' || !userStore.user.is_email_verified">
      <LazyNavMobile />
      <Footer class="lg:hidden" />
    </template>
    <LazyModalsBackCall />
    <LazyModalsBackCallFilesScenario />
  </SwipeReload>
</template>

<script setup>
import { Camera } from '@capacitor/camera';
const route = useRoute();

const nuxtApp = useNuxtApp();
const notieStore = useNotieStore();
const userStore = useUserStore();

onMounted(async () => {
  if (usePlatform().value !== 'web')
    await Camera.requestPermissions({ permissions: ['camera', 'photos'] });
  try {
    await nuxtApp.$ws.useConnection();
  } catch (err) {}
});
</script>

<style scoped></style>
