<template>
  <div
    @touchstart="handleTouchStart"
    @touchmove="handleTouchMove"
    @touchend="handleTouchEnd"
  >
    <slot></slot>
  </div>
</template>

<script setup>
const startY = ref(0);
const endY = ref(0);
// const router = useRouter();
// const route = useRoute();
// const link = ref('');
function handleTouchStart(event) {
  startY.value = event.touches[0].clientY;
}

function handleTouchMove(event) {
  endY.value = event.touches[0].clientY;
}

function handleTouchEnd() {
  if (startY.value - endY.value < -350 && window.scrollY === 0) {
    // Свайп вверх обнаружен, перезагрузите страницу
    // navigateTo(route.fullPath, { force: true });
    // window.location.reload();
    // router.go(0);
    startY.value = 0;
  }
}
</script>

<style scoped>
/* Добавьте стили, если необходимо */
</style>
